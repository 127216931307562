@font-face {
  font-family: 'makguide';
  src:  url('../../fonts/makguide.eot?ctbvee');
  src:  url('../../fonts/makguide.eot?ctbvee#iefix') format('embedded-opentype'),
  url('../../fonts/makguide.ttf?ctbvee') format('truetype'),
  url('../../fonts/makguide.woff?ctbvee') format('woff'),
  url('../../fonts/makguide.svg?ctbvee#makguide') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $iconFont !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  //* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lab:before {
  content: "\e958";
}
.icon-bookmark-full:before {
  content: "\e949";
}
.icon-bookmark-outline:before {
  content: "\e94a";
}
.icon-letter:before {
  content: "\e94b";
}
.icon-map-hide:before {
  content: "\e94c";
}
.icon-map-show:before {
  content: "\e94d";
}
.icon-plus-map-thick:before {
  content: "\e94e";
}
.icon-restaurant:before {
  content: "\e94f";
}
.icon-speed1:before {
  content: "\e950";
}
.icon-speed2:before {
  content: "\e951";
}
.icon-speed05:before {
  content: "\e952";
}
.icon-speed15:before {
  content: "\e953";
}
.icon-speed075:before {
  content: "\e954";
}
.icon-speed125:before {
  content: "\e955";
}
.icon-speed175:before {
  content: "\e956";
}
.icon-wardrobe:before {
  content: "\e957";
}
.icon-x:before {
  content: "\e900";
}
.icon-airplane:before {
  content: "\e901";
}
.icon-ar:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-arrow-extern:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-arrow-up:before {
  content: "\e907";
}
.icon-assistant:before {
  content: "\e908";
}
.icon-audio:before {
  content: "\e909";
}
.icon-bell-notification:before {
  content: "\e90a";
}
.icon-bell:before {
  content: "\e90b";
}
.icon-cards:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e90d";
}
.icon-checkout:before {
  content: "\e90e";
}
.icon-chevron-down:before {
  content: "\e90f";
}
.icon-chevron-left:before {
  content: "\e910";
}
.icon-chevron-right:before {
  content: "\e911";
}
.icon-chevron-up:before {
  content: "\e912";
}
.icon-clock:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e914";
}
.icon-code:before {
  content: "\e915";
}
.icon-compass-needle:before {
  content: "\e916";
}
.icon-compass:before {
  content: "\e917";
}
.icon-copy:before {
  content: "\e918";
}
.icon-darkmode:before {
  content: "\e919";
}
.icon-delete:before {
  content: "\e91a";
}
.icon-direction:before {
  content: "\e91b";
}
.icon-dropdown-up:before {
  content: "\e91c";
}
.icon-dropdown:before {
  content: "\e91d";
}
.icon-eco:before {
  content: "\e91e";
}
.icon-elevator:before {
  content: "\e91f";
}
.icon-enlarge:before {
  content: "\e920";
}
.icon-equalizer:before {
  content: "\e921";
}
.icon-feed:before {
  content: "\e922";
}
.icon-filter:before {
  content: "\e923";
}
.icon-forward:before {
  content: "\e924";
}
.icon-fullscreen:before {
  content: "\e925";
}
.icon-greif:before {
  content: "\e926";
}
.icon-heart-full:before {
  content: "\e927";
}
.icon-heart-outline:before {
  content: "\e928";
}
.icon-home:before {
  content: "\e929";
}
.icon-info:before {
  content: "\e92a";
}
.icon-lang:before {
  content: "\e92b";
}
.icon-link:before {
  content: "\e92c";
}
.icon-list:before {
  content: "\e92d";
}
.icon-locked:before {
  content: "\e92e";
}
.icon-minus:before {
  content: "\e92f";
}
.icon-more:before {
  content: "\e930";
}
.icon-pause-circle:before {
  content: "\e931";
}
.icon-pause:before {
  content: "\e932";
}
.icon-pin:before {
  content: "\e933";
}
.icon-play-circle:before {
  content: "\e934";
}
.icon-play:before {
  content: "\e935";
}
.icon-playlists:before {
  content: "\e936";
}
.icon-plus:before {
  content: "\e937";
}
.icon-questionmark:before {
  content: "\e938";
}
.icon-rewind:before {
  content: "\e939";
}
.icon-rooms:before {
  content: "\e93a";
}
.icon-route:before {
  content: "\e93b";
}
.icon-scan:before {
  content: "\e93c";
}
.icon-search:before {
  content: "\e93d";
}
.icon-share-arrow:before {
  content: "\e93e";
}
.icon-share-arrow2:before {
  content: "\e93f";
}
.icon-share-dots:before {
  content: "\e940";
}
.icon-shop:before {
  content: "\e941";
}
.icon-station-dot:before {
  content: "\e942";
}
.icon-tilde:before {
  content: "\e943";
}
.icon-toilet:before {
  content: "\e944";
}
.icon-transcript:before {
  content: "\e945";
}
.icon-unlocked:before {
  content: "\e946";
}
.icon-video-player:before {
  content: "\e947";
}
.icon-wcbarrierefrei:before {
  content: "\e948";
}
