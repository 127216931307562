//colors
$none: transparent;
$white: #FFFFFF;
$red: #FF0000;
$black: #000000;

$black-light: #181818;
$blue: #005afa;
$sketches-brown: #a46c49;
$gray-100: #e6e6e6;
$gray-200: #bbb;
$gray-150: #dbdbdb;
$black-pure: #000;
$gray-250: #999;
$gray-300: #444;
$gray-275: #666;
$gray-400: #333;
$gray-50: #f4f4f4;
$gray-75: #eee;
$hellgrean: #d7ebcc;
$red: #ff4600;
$rosarosa: #fa969c;
$tuerkis: #6be0c2;
$violett: #7d66f0;
$white: #fff;
$yellow: #ff0;
$yellow-100: #d5d500;
$yellow-200: #736f00;
$beige-150: #b9b6af;
$beige-100: #f3efe4;
$beige-300: #7f7b72;
$yellow-150: #acac00;
$beige-200: #989185;
$blue-100: #0b3989;

$mapBackgroundColor: #f3efe4;
$blue: #005afa;
$border-blue: #0b3989;



// Next CD

//$0-open-green: #00c92c;
$beige-100: #dad2b6;
//$0-red-labapp: #f00;
$cd-beige-120: #99937f;
$cd-beige-110: #c6bfa5;
$sketches-brown: #a46c49;
$cd-beige-200: #4d4a40;
$black-light: #181818;
$beige-200: #9b978f;
$black-pure: #000;
$beige-300: #7f7b72;
$beige-150: #b9b6af;
$blue: #005afa;
$blue-100: #0b3989;
$gray-50: #f4f4f4;
$gray-100: #e6e6e6;
$gray-75: #eee;
$gray-150: #dbdbdb;
$gray-200: #bbb;
$gray-250: #999;
$gray-275: #666;
$gray-300: #444;
$gray-400: #333;
$rosarosa: #fa969c;
$tuerkis: #6be0c2;
$violett: #7d66f0;
$white: #fff;
$yellow: #ff0;
$hellgrean: #d7ebcc;
$yellow-100: #d5d500;
$yellow-150: #acac00;
$yellow-200: #736f00;
$cd-orange: #fb5001;
$cd-pastel-blue-100: #8eadc4;
$label-color-light-primary: #000;
$cd-orange: #fb5001;
$system-background-light-primary: #fff;
$label-color-light-secondary: rgba(60, 60, 67, 0.6);
$default-system-blue-light: #007aff;
$cd-beige-100: #dad2b6;
$black-pure: #000;

$mapBackgroundColor: #E9E1CF;

//breakpoints
$breakpointMobile: 576px;
$breakpointDesktop: 768px;
$breakpointLarge: 992px;
$breakpointExtraLarge: 1200px;
$breakpointHuge: 1440px;
$breakpointGiant: 1840px;

// Font Families
$iconFont: "makguide";
$tstar: GerstnerProgrammMAKWeb, SansSerif;
$tstarPro: GerstnerProgrammMAKWeb, SansSerif;
$untitledSans: GerstnerProgrammMAKWeb, SansSerif;
$gerstnerProgrammMAKWeb: GerstnerProgrammMAKWeb, SansSerif;

//font-weights
$lightFontWeight: 300;
$regularFontWeight: 400;
$boldFontWeight: bold;

//bootstrap variables
$containerPadding: 2rem;
$containerPaddingSmall: 1rem;
$columnGap: 1rem;

// page modules variables
$mainNavHeight: 5rem;

// mixins
@mixin dashboard-icon {
  font-size: 2.6rem;
  padding: 0.8rem;
  display: block;
  transition: background-color .3s ease, backdrop-filter .3s ease;

}

@mixin dashboard-icon-background {
  backdrop-filter: blur(80px);
  background-color: rgba(0,0,0,0.05);
}

@mixin hide-scrollbar {

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin played-tag {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem 0.4rem;
  background-color: $cd-orange;
  color: $black-pure;
  text-transform: uppercase;
  @include infolabel-s-11_100-uppercase();

  .icon {
    margin-right: 0.35rem;
  }
}

@mixin addTapAnimation {
  &:active {
    animation: buttonTapAnimation .3s forwards;
  }
}

// imported in styles.scss
@mixin buttonTapAnimation {
  @keyframes buttonTapAnimation {
    0% {
      transform: scale(1.0);
    }
    33% {
      transform: scale(0.95);
    }
    66% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1.0)
    }
  }
}
