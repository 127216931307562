@font-face {
  font-family: T-StarTWPRO;
  src: url('../../fonts/T-Star-TW-pro-bold-webfont.woff2') format("woff2");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: TSTAR;
  src: url('../../fonts/T-StarPRO-Medium.woff2') format("woff2");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: UntitledSans;
  src: url('../../fonts/untitled-sans-regular.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: UntitledSans;
  src: url('../../fonts/untitled-sans-medium.woff2') format("woff2");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: GerstnerProgrammMAKWeb;
  src: url('../../fonts/GerstnerProgrammMAKWeb-Regular.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: GerstnerProgrammMAKWeb;
  src: url('../../fonts/GerstnerProgrammMAKWeb-Medium.woff2') format("woff2");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: GerstnerProgrammMAKWeb;
  src: url('../../fonts/GerstnerProgrammMAKWeb-MediumItalic.woff2') format("woff2");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: GerstnerProgrammMAKWeb;
  src: url('../../fonts/GerstnerProgrammMAKWeb-Italic.woff2') format("woff2");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
