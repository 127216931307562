/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/frontend";

:root {
  background-color: $white;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //width: 100%;
  //height: 100%;
  //overflow-x: hidden;
  //overflow-y: auto;

  --viewHeight: 100vh;

  &.ios {
    --viewHeight: calc(100vh - 12rem);
  }

  &.ios-new {
    --viewHeight: calc(100vh - 8rem);
  }

  &.overflow-hidden {
    overflow: hidden;
    height: 100vh;
  }
}

@include buttonTapAnimation();
