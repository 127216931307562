html {
  box-sizing: border-box;
  font-size: 10px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

@media screen and (min-width: 992px) {
  html {
    font-size: calc(6.6px + (100vw - 992px) * (6.6 / 992));
  }
}

@media screen and (min-width: 1322px) {
  html {
    font-size: calc(6px + (100vw - 992px) * (6 / 992));
  }
}

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:active, *:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
}

// Hides Scrollbar on Page Transition
body.overflow-hidden {
  overflow: hidden;
}

h1, h2, h3, h4 {
  margin: 0;
}

video, img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0 0 1em 0;
}

p {
  margin: 0 0 1em 0;
}

a {
  color: inherit;
  position: relative;
  text-decoration: none;
}

.col {
  &--no-padding-left {
    padding-left: 0;
    margin-left: -1.2rem;
  }

  &--no-padding-right {
    padding-right: 0;
  }
}


// Used in Map Component for Labels
.show-en, .show-de {
  display: none;
}

.current-lang-de {
  .show-de {
    display: initial;
  }
}
.current-lang-en {
  .show-en {
    display: initial;
  }
}


.player-visible--mini {
  .location {
    &__level-selector-wrapper {
      transform: translateY(calc(-#{$mainNavHeight} - 1rem));
    }
  }
  .map {
    .leaflet-bottom.leaflet-right {
      transform: translateY(calc(-#{$mainNavHeight} - 1rem));
    }
  }
}

body.custom-language {
  *:not([class^="icon-"], [class*=" icon-"]) {
    font-family: "San Francisco", Roboto, sans-serif !important;
  }
}



