@import "../vars.scss";

.patt-inverted {
  .patt-wrap {
    &:after {
      background-image: url("/assets/img/pattern_cat_0_inverted.svg");
    }
  }
  .patt-dots {
    background: #000;

    &:after {
      border: 2px solid $white;
    }
  }
}

.patt-holder {
  -ms-touch-action: none;
  margin: 0 auto;
  max-width: 400px;
}

.patt-wrap {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.patt-wrap ul,
.patt-wrap li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.patt-circ {
  position: relative;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 3rem;
  &.hovered {
    .patt-dots {
      background: $black-pure;
    }
  }
}

.patt-error .patt-circ.hovered {
  border: 3px solid #ba1b26;
}

.patt-hidden .patt-circ.hovered {
  border: 0;
}

.patt-dots {
  background: #fff;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // Dots
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 6.4rem;
    max-height: 6.4rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 0.4rem solid $black;
  }
}

.patt-lines {
  border-radius: 4px;
  height: 1.4rem;
  background: $black-pure;
  position: absolute;
  transform-origin: 0.7rem 0.7rem;
}

.patt-hidden .patt-lines {
  display: none;
}

@media (min-width: $breakpointLarge) {
  .patt-dots {

    width: 3rem;
    height: 3rem;

    &:after {
      width: 250%;
      height: 250%;
    }

  }

  .patt-lines {
    height: 10px;
    transform-origin: 5px 5px;
  }
}
